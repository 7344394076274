import { ToastContainer } from 'react-toastify';
import { useTypedRouteLoaderData } from 'remix-typedjson';
import { GlobalNavigationSidebar } from '~/global/components/GlobalNavigationSidebar';
import { RootLoaderData } from '~/root';

export interface GlobalApplicationProps {
  children: React.ReactElement;
}

/**
 * @name GlobalApplication
 * @description TODO: Add a description
 */
export const GlobalApplication = (props: GlobalApplicationProps) => {
  const { children } = props;

  // Hooks
  const data = useTypedRouteLoaderData('root') as RootLoaderData;

  // Setup
  const { user } = data;
  const isAuthorized = !!user?.uuid;

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <>
      <div className="flex h-screen gap-4">
        {isAuthorized && <GlobalNavigationSidebar />}
        <div className="flex-1 overflow-auto">{children}</div>
      </div>
      <ToastContainer />
    </>
  );
};
