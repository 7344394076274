import { Sidebar } from '@shiftsmartinc/remix-utils';
import { useState } from 'react';
import { getSidebarLinks } from '~/global/data/links.navigation';

export interface GlobalNavigationSidebarProps {}

export const GlobalNavigationSidebar = (
  _props: GlobalNavigationSidebarProps,
) => {
  // Hooks
  const [collapsed, setCollapsed] = useState(false);

  // Setup
  const links = getSidebarLinks();

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <Sidebar
      className="h-full"
      isCollapsed={collapsed}
      links={links}
      onToggleCollapse={setCollapsed}
    />
  );
};
