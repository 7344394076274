import {
  Bus,
  FileCode,
  FileDoc,
  SignOut,
  SneakerMove,
  UsersThree,
} from '@phosphor-icons/react';
import { SidebarLink } from '@shiftsmartinc/remix-utils';

export const getSidebarLinks = (): SidebarLink[] => [
  {
    icon: <FileCode className="h-5 w-5" />,
    title: 'Home',
    to: '/',
  },
  {
    icon: <FileDoc className="h-5 w-5" />,
    title: 'Documenation',
    to: '/docs',
  },
  {
    icon: <SneakerMove className="h-5 w-5" />,
    title: 'Runbooks',
    to: '/runbooks',
  },
  {
    icon: <UsersThree className="h-5 w-5" />,
    title: 'Team',
    to: '/team',
  },

  // TEMP DIVIDER....
  {
    className: 'flex-1 pointer-events-none',
    icon: <Bus className="h-5 w-5 opacity-0" />,
    title: '',
    to: '/not-actually-used',
  },

  // 🚨 Important 🚨 Note the use of "reloadDocument" below
  {
    icon: <SignOut className="h-5 w-5" />,
    prefetch: 'none',
    reloadDocument: true,
    title: 'Logout',
    to: '/auth/logout',
  },

  // {
  //   icon: <PenNib className="h-5 w-5" />,
  //   title: 'Blog',
  //   to: '/blog',
  // },
  // {
  //   icon: <Umbrella className="h-5 w-5" />,
  //   title: 'Sandbox',
  //   to: '/sandbox',
  // },
];
